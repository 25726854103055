import React, { useEffect, useMemo, useState } from 'react'
import pepLogoWhite from '../../resources/assets/images/peperoni_white_logo.png'
import TopicCard from '../TopicCard/TopicCard'
import './Sidebar.sass'
import SectionTitle from '../SectionTitle/SectionTitle'
import { useAppSelector } from '../../store/reducers/store'
import { Hint, Topic } from '@22hbg/peperoni-ai-sdk'
import { FetchHintsRequestParams } from '../../store/actions/thunk_actions'
import { BASE_URL } from '../../pages/auth/LoginPage'
import { getRequestHeader, serializeObjectToQueryParams } from '../../utility/functions'
import axios from 'axios'
import HintCard from '../HintCard/HintCard'

const Sidebar: React.FC = () => {
    const data = useAppSelector((data) => data.data)
    const user = useAppSelector((data) => data.user)

    const [selectedTopic, setSelectedTopic] = useState<Topic | undefined>(undefined)
    const [hints, setHints] = useState<Hint[]>([])

    useEffect(() => {
        void fetchHints()
    }, [selectedTopic])

    const fetchHints = async () => {
        if (selectedTopic === undefined) {
            return
        }

        setHints([])

        try {
            const fetchHintsParams: FetchHintsRequestParams = {
                // top: true,
                topic: selectedTopic.id,
            }

            const hints: Hint[] = await axios
                .get(
                    `${BASE_URL}hints?${serializeObjectToQueryParams(fetchHintsParams)}`,
                    getRequestHeader(user.accessToken ?? '')
                )
                .then((response) => response.data.items)

            setHints(hints)
        } catch (e) {
            console.error('Request error: ', e)
        }
    }

    const sectionTitle = useMemo(() => {
        if (selectedTopic === undefined) {
            const formattedUserName = user.loggedUserInfo?.user_info.first_name.split('.')[0]
            return (
                <div>
                    <SectionTitle text={`Ciao ${formattedUserName} 👋 `} />
                    <SectionTitle text="come posso aiutarti?" />
                </div>
            )
        }

        return (
            <b style={{ cursor: 'pointer' }} onClick={() => setSelectedTopic(undefined)}>
                {`< ${selectedTopic.title}`}
            </b>
        )
    }, [selectedTopic])

    const itemList = useMemo(() => {
        if (selectedTopic === undefined) {
            return data.topics.map((topic, i) => (
                <TopicCard key={topic.id} topic={topic} index={i} onClick={() => setSelectedTopic(topic)} />
            ))
        }

        return hints.map((hint, index) => <HintCard key={`hint-${hint.id}`} hint={hint} index={index} />)
    }, [selectedTopic, data.topics, hints])

    return (
        <div className="main-sidebar">
            <img className="main-sidebar__logo" src={pepLogoWhite} alt="PeperoniAI" />

            {sectionTitle}

            <div className="main-sidebar__topics-container">{itemList}</div>
            <div className="main-sidebar__bottom-section">
                <p className="main-sidebar__bottom-section__bottom-credits">
                    Made with ❤️ by{' '}
                    <a className="sidebar-external-link" href="https://22hbg.com/" target="_blank" rel="noreferrer">
                        22HBG
                    </a>
                </p>
                <p className="main-sidebar__bottom-section__bottom-credits">
                    2023 22HBG S.R.L. All rights reserved. 22HBG is a registered trademark of 22HBG S.R.L. - P.IVA
                    IT01855410385
                </p>
            </div>
        </div>
    )
}

export default Sidebar
