import axios from 'axios'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import GradientButton from '../../components/GradientButton/GradientButton'
import SectionTitle from '../../components/SectionTitle/SectionTitle'
import TextInput, { TextType } from '../../components/TextInput/TextInput'
import { ROUTES } from '../../resources/routes-constants'
import './../../styles/Pages/auth_pages.sass'
import pepWhiteLogo from '../../resources/assets/images/peperoni_white_logo.png'

const SSO_PROD_URL = 'https://api.peperoni.ai/22sso-api/'

const ResetPasswordPage: React.FC = () => {
    const [email, setEmail] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    const login = async () => {
        try {
            setIsLoading(true)
            await axios.post(`${SSO_PROD_URL}/auth/reset-password?email=${email}`).then((response) => response.data)
            navigate(ROUTES.LOGIN_ROUTE)
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }

    return (
        <div className="auth-page-main">
            <div className="auth-page-main__inner">
                <div className="auth-page-main__inner__header">
                    <div className="main-logo-container">
                        <img src={pepWhiteLogo} alt="PeperoniAI" />
                    </div>
                    <SectionTitle text="Reset password" />
                </div>
                <div className="auth-input-container">
                    <TextInput
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        textType={TextType.Email}
                        placeholder="Email"
                    />
                </div>
                <GradientButton disabled={isLoading || !email} onClick={() => void login()}>
                    {isLoading ? 'Invio in corso...' : 'Invia'}
                </GradientButton>
                <div className="auth-internal-link-container">
                    <Link className="auth-internal-link" to={ROUTES.LOGIN_ROUTE}>
                        Accedi
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordPage
