import { createReducer } from '@reduxjs/toolkit'
import { LoggedUser } from '../../types/data'
import { logoutUser, setAccessToken, setLoggedUserInfo, setUserPreferredFontSize } from '../actions/user'

interface UserReducer {
    loggedUserInfo: LoggedUser | null
    accessToken: string | null
    userPreferredFontSize: number
}

const initialState: UserReducer = {
    loggedUserInfo: null,
    accessToken: null,
    userPreferredFontSize: 14,
}

const dataReducer = createReducer<UserReducer>(initialState, (builder) => {
    builder.addCase(setLoggedUserInfo, (state, action) => {
        state.loggedUserInfo = action.payload
    })
    builder.addCase(setAccessToken, (state, action) => {
        state.accessToken = action.payload
    })
    builder.addCase(setUserPreferredFontSize, (state, action) => {
        state.userPreferredFontSize = action.payload
    })
    builder.addCase(logoutUser, (state) => {
        state.loggedUserInfo = null
        state.accessToken = null
    })
})

export default dataReducer
