import React from 'react'
import Header from '../components/Header/Header'
import Sidebar from '../components/Sidebar/Sidebar'
import ChatPanel from '../customComponents/ChatPanel/ChatPanel'

const HomePage: React.FC = () => {
    return (
        <div>
            <Sidebar />
            <Header title="PeperoniAI" />
            <div
                style={{
                    marginLeft: '250px',
                    marginTop: '60px',
                }}
            >
                <ChatPanel />
            </div>
        </div>
    )
}

export default HomePage
