import React from 'react'
import './SectionTitle.sass'

interface SectionTitleProps {
    text: string
}

const SectionTitle: React.FC<SectionTitleProps> = (props) => {
    return <span className="section-main-title">{props.text}</span>
}

export default SectionTitle
