import { Topic } from '@22hbg/peperoni-ai-sdk'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { LoggedUser, Plan, SSOUserInfo } from '../../types/data'
import { getRequestHeader } from '../../utility/functions'
import { AppDispatch, RootState } from '../reducers/store'
import { setPlans, setTopics } from './data'
import { setLoggedUserInfo } from './user'

const BASE_URL = 'https://solutions.api-services.cloud/peperoni-ai/'
const SSO_BASE_URL = 'https://api.peperoni.ai/22sso-api/'

export interface BasicRequestParams {
    offset?: number
    limit?: number
    sort_by?: string
    order_by?: 'ASC' | 'DESC'
}

export interface FetchHistoryParams extends BasicRequestParams {
    user: number
}

export interface FetchHintsRequestParams extends BasicRequestParams {
    top?: boolean
    topic?: number
    title?: string
}

interface PatchUserParams {
    firstName?: string
    lastName?: string
    username?: string
}

// --- General data

export const fetchData = createAsyncThunk<void, void, { dispatch: AppDispatch }>(
    'groupedActions/fetchData',
    async (_, { dispatch }) => {
        await dispatch(fetchUserInfo())
        await dispatch(fetchTopics())
        await dispatch(fetchAvailablePlans())
    }
)

export const fetchTopics = createAsyncThunk<void, void, { dispatch: AppDispatch; state: RootState }>(
    'groupedActions/fetchTopics',
    async (_, { dispatch, getState }) => {
        try {
            const authToken = getState().user.accessToken

            if (authToken === null) {
                return
            }

            const topics: Topic[] = await axios
                .get(`${BASE_URL}topics`, getRequestHeader(authToken))
                .then((response) => response.data.items)

            await dispatch(setTopics(topics))
        } catch (e) {
            console.error(e)
        }
    }
)

export const fetchAvailablePlans = createAsyncThunk<void, void, { dispatch: AppDispatch; state: RootState }>(
    'groupedActions/fetchAvailablePlans',
    async (_, { dispatch, getState }) => {
        try {
            const authToken = getState().user.accessToken

            if (authToken === null) {
                return
            }

            const plans: Plan[] = await axios
                .get(`${BASE_URL}plans`, getRequestHeader(authToken))
                .then((response) => response.data.items)

            await dispatch(setPlans(plans))
        } catch (e) {
            console.error(e)
        }
    }
)

// ---- User info

export const fetchUserInfo = createAsyncThunk<void, void, { dispatch: AppDispatch; state: RootState }>(
    'groupedActions/fetchUserInfo',
    async (_, { dispatch, getState }) => {
        try {
            const authToken = getState().user.accessToken

            if (authToken === null) {
                return
            }

            const loggedUser: LoggedUser = await axios
                .get(`${BASE_URL}user`, getRequestHeader(authToken))
                .then((response) => response.data)

            await dispatch(setLoggedUserInfo(loggedUser))
        } catch (e) {
            console.error(e)
        }
    }
)

export const patchUserData = createAsyncThunk<
    void,
    { authToken: string; params: PatchUserParams },
    { state: RootState; dispatch: AppDispatch }
>('groupedActions/patchUserData', async ({ authToken, params }, { dispatch, getState }) => {
    try {
        const userObject = getState().user.loggedUserInfo
        const userInfoCopy = {
            ...userObject?.user_info,
            first_name: params.firstName,
            last_name: params.lastName,
            username: params.username,
        }
        const patchedUserData: SSOUserInfo = await axios
            .put(`${SSO_BASE_URL}users/${userObject?.sso_uuid}`, userInfoCopy, getRequestHeader(authToken))
            .then((response) => response.data)

        const patchedUser = userObject ? { ...userObject, user_info: patchedUserData } : null
        if (patchedUser) await dispatch(setLoggedUserInfo(patchedUser))
    } catch (e) {
        console.error(e)
    }
})
