import { ReactNode } from 'react'
import './GradientButton.sass'

interface Props {
    children: ReactNode
    type?: 'grayscale'
    disabled?: boolean
    onClick: () => void
}

const GradientButton: React.FC<Props> = (props) => {
    return (
        <button
            className={`gradient-button ${props.type === 'grayscale' ? 'grayscale-button' : ''}`}
            disabled={!!props.disabled}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    )
}

export default GradientButton
