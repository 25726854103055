import { ChangeEventHandler } from 'react'
import './TextInput.sass'

export enum TextType {
    Text = 'text',
    Email = 'email',
    Password = 'password',
}

interface Props {
    value: string
    textType: TextType
    onChange: ChangeEventHandler<HTMLInputElement>
    placeholder: string
    autoCapitalize?: boolean
    autoCorrect?: boolean
    autoComplete?: boolean
}

const TextInput: React.FC<Props> = (props) => {
    return (
        <div className="text-input-wrapper">
            <input
                className="text-input"
                type={props.textType}
                value={props.value}
                onChange={props.onChange}
                placeholder={props.placeholder}
                autoCapitalize={props.autoCapitalize ? 'on' : 'off'}
                autoCorrect={props.autoCorrect ? 'on' : 'off'}
                autoComplete={props.autoComplete ? undefined : 'new-password'}
            />
        </div>
    )
}

export default TextInput
