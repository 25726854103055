import { MessageType, ParsedMessage } from '@22hbg/peperoni-ai-sdk'
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import ChatMessage from '../../components/ChatMessage/ChatMessage'
import { formatDateToFriendlyString } from '../../utility/functions'
import { SectionListGroup, usePrevious } from '../../utility/hooks'
import './ChatList.sass'
import InfiniteLoader, { InfiniteLoaderElement } from '../InfiniteLoader/InfiniteLoader'
import LoadingMessage from '../../components/ChatMessage/LoadingMessage'
import ErrorMessage from '../../components/ChatMessage/ErrorMessage'
import CircularLoader from '../InfiniteLoader/CircularLoader'

interface Props {
    messageList: SectionListGroup[]
    loading: boolean
    onSendError: (id: string) => void
    onLoadMore: () => void
}

export interface ChatListElement {
    scrollToBottom: () => void
}

const ChatList = forwardRef<ChatListElement, Props>((props, ref) => {
    const loaderRef = useRef<InfiniteLoaderElement | null>(null)
    const previousMessageListLength = usePrevious(props.messageList.length)

    useImperativeHandle(ref, () => ({
        scrollToBottom: (): void => {
            if (!loaderRef || !loaderRef.current) {
                return
            }

            loaderRef.current?.scrollToBottom()
        },
    }))

    useEffect(() => {
        if (
            previousMessageListLength !== undefined &&
            previousMessageListLength === 0 &&
            props.messageList.length > 0
        ) {
            loaderRef.current?.scrollToBottom()
        }
    }, [props.messageList])

    return (
        <div className="chat-message-list">
            <InfiniteLoader
                ref={loaderRef}
                dataLength={props.messageList.flatMap((section) => section.data).length}
                loading={props.loading}
                loadMore={() => props.onLoadMore()}
                loaderComponent={
                    <div style={{ position: 'absolute', top: 8, left: 0, width: '100%' }}>
                        <CircularLoader />
                    </div>
                }
            >
                {props.messageList.map((section) => {
                    return (
                        <div className="chat-message-list__time-section" key={section.title}>
                            <div className="chat-message-list__time-section__header-chip">
                                {formatDateToFriendlyString(section.title)}
                            </div>
                            <div className="chat-message-list__time-section__messages-container">
                                {section.data.map((message) => {
                                    if (message.messageType === MessageType.Loading) {
                                        return <LoadingMessage key={message.id} />
                                    }

                                    if (message.messageType === MessageType.Error) {
                                        return (
                                            <ErrorMessage
                                                key={message.id}
                                                onClick={() => props.onSendError(message.id)}
                                            />
                                        )
                                    }

                                    return <ChatMessage key={message.id} message={message as ParsedMessage} />
                                })}
                            </div>
                        </div>
                    )
                })}
            </InfiniteLoader>
        </div>
    )
})

ChatList.displayName = 'ChatList'

export default ChatList
