import { Hint, ParsedMessage } from '@22hbg/peperoni-ai-sdk'
import { faBrain, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ChatUserInput from '../../components/ChatUserInput/ChatUserInput'
import Tooltip from '../../components/Tooltip/Tooltip'
import { setSelectedHint } from '../../store/actions/data'
import { fetchUserInfo } from '../../store/actions/thunk_actions'
import { useAppDispatch, useAppSelector } from '../../store/reducers/store'
import { groupListByDate, usePeperoniAiChat, usePrevious } from '../../utility/hooks'
import ChatList, { ChatListElement } from '../ChatList/ChatList'
import './ChatPanel.sass'

const ChatPanel: React.FC = () => {
    const listRef = useRef<ChatListElement | null>(null)

    const data = useAppSelector((store) => store.data)
    const dispatch = useAppDispatch()

    const user = useAppSelector((store) => store.user)

    const [messageText, setMessageText] = useState<string>('')
    const [contextIsEnabled, setContextIsEnabled] = useState<boolean>(false)
    const [contextWasManuallySet, setContextWasManuallySet] = useState<boolean>(false)

    const [list, loading, sentMessagesLength, sendMessage, onLoadMore] = usePeperoniAiChat(
        user.accessToken ?? '',
        user.loggedUserInfo?.id || -1
    )

    const fetchUserBalance = async () => {
        try {
            await dispatch(fetchUserInfo())
        } catch (error) {
            console.error(error)
        }
    }

    const groupedList = groupListByDate(list)

    const previousSelectedHint: Hint | undefined = usePrevious(data.selectedHint)

    useEffect(() => {
        if (!contextWasManuallySet) setContextIsEnabled(sentMessagesLength > 0)
    }, [sentMessagesLength, contextWasManuallySet])

    useEffect(() => {
        if (
            (previousSelectedHint === undefined && data.selectedHint !== undefined) ||
            (previousSelectedHint !== undefined &&
                data.selectedHint !== undefined &&
                previousSelectedHint.id !== data.selectedHint.id)
        ) {
            setMessageText(data.selectedHint.prefill)
            if (!contextWasManuallySet) setContextIsEnabled(false)
        }
    }, [data.selectedHint, contextWasManuallySet])

    const onSendMessage = useCallback(() => {
        // eslint-disable-next-line no-async-promise-executor
        void new Promise<void>(async (resolve) => {
            await sendMessage({
                message: messageText,
                selectedHint: data.selectedHint,
                selectedParams: [],
                context: contextIsEnabled,
            })
            void fetchUserBalance()

            resolve()
        }).then(() =>
            setTimeout(() => {
                listRef.current?.scrollToBottom()
            }, 100)
        )

        setTimeout(() => {
            listRef.current?.scrollToBottom()
            setMessageText('')
        }, 100)

        if (data.selectedHint !== undefined) {
            dispatch(setSelectedHint(undefined))
        }
    }, [messageText, data.selectedHint])

    // TODO: Da ripristinare
    // const parametersButton = useMemo(() => {
    //     if (data.selectedHint === undefined) {
    //         return null
    //     }

    //     if (data.selectedHint.parameters === null) {
    //         return null
    //     }

    //     if (data.selectedHint.parameters.length === 0) {
    //         return null
    //     }

    //     return (
    //         <div className="chat-input-section__inner__button">
    //             <FontAwesomeIcon icon={faSlidersH} />
    //         </div>
    //     )
    // }, [data.selectedHint])

    return (
        <div className="chat-panel-wrapper">
            <div className="chat-panel-background" />
            <ChatList
                ref={listRef}
                messageList={groupedList}
                onSendError={(id) => {
                    const foundMessage = list.find((message) => message.id === `${parseInt(id, 10)}-request`)

                    void sendMessage({
                        message: (foundMessage as ParsedMessage).content,
                        selectedHint: data.selectedHint,
                        selectedParams: [],
                        context: contextIsEnabled,
                    })
                }}
                loading={loading}
                onLoadMore={() => void onLoadMore()}
            />
            <div className="chat-input-section">
                <div className="chat-input-section__inner">
                    {/* {parametersButton} */}
                    <Tooltip position="top" text={`Contesto ${contextIsEnabled ? 'attivato' : 'disattivato'}`}>
                        <div
                            className={`chat-input-section__inner__button ${
                                contextIsEnabled ? 'active-context-feature' : ''
                            }`}
                            onClick={() => {
                                setContextWasManuallySet(true)
                                setContextIsEnabled(!contextIsEnabled)
                            }}
                        >
                            <FontAwesomeIcon icon={faBrain} />
                        </div>
                    </Tooltip>
                    <ChatUserInput value={messageText} onChange={(newVal) => setMessageText(newVal)} />
                    <Tooltip position="top" text="Invia">
                        <div
                            className={`chat-input-section__inner__button chat-input-section__inner__button--primary ${
                                messageText ? '' : 'disabled-chat-input-button'
                            }`}
                            onClick={() => onSendMessage()}
                        >
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </div>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}

export default ChatPanel
