import {
    ContentType,
    Hint,
    HistoryMessage,
    MessageType,
    ParsedMessage,
    parseHistoryMessages,
    parseResponseMessage,
    ResponseMessage,
} from '@22hbg/peperoni-ai-sdk'
import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { BASE_URL } from '../pages/auth/LoginPage'
import { FetchHistoryParams } from '../store/actions/thunk_actions'
import { serializeObjectToQueryParams, getRequestHeader } from './functions'

interface SelectedParamValue {
    idParam: number
    idValue: number
}

interface MessageParams {
    message: string
    selectedHint?: Hint
    selectedParams: SelectedParamValue[]
    context?: boolean
}

interface CompletionParameters {
    idParam: number
    idOption: number
}

export interface FetchCompletionBody {
    prompt: string
    hint: number | null
    entrypoint: number
    type: 'text' | 'image'
    parameters: CompletionParameters[]
    context: boolean | null
}

export const performCompletion = async (body: FetchCompletionBody, authToken: string): Promise<ResponseMessage> => {
    try {
        const message: ResponseMessage = await axios
            .post(`${BASE_URL}completion`, body, getRequestHeader(authToken))
            .then((response) => response.data.response)

        return message
    } catch (e) {
        console.error('eerror: ', e)
        throw e
    }
}

type MessageRequest = (requestMessage: MessageParams) => Promise<void>
type NextPageRequest = () => Promise<void>

export interface OtherMessage {
    id: string
    datetime: number
    messageType: MessageType
}

const itemsPerPage = 8

export const usePeperoniAiChat = (
    userAccessToken: string,
    userId: number
): [(ParsedMessage | OtherMessage)[], boolean, number, MessageRequest, NextPageRequest] => {
    const [list, setList] = useState<(ParsedMessage | OtherMessage)[]>([])
    const [sentMessagesLength, setSentMessagesLength] = useState<number>(0)
    const [loadingData, setLoadingData] = useState<boolean>(false)
    const [hasMoreItemToFetch, setHasMoreItemToFetch] = useState<boolean>(true)
    const currentPage = useRef<number>(0)

    useEffect(() => {
        void fetchList()
    }, [])

    const fetchList = async () => {
        if (!hasMoreItemToFetch) {
            return
        }

        setLoadingData(true)

        try {
            const params: FetchHistoryParams = {
                user: userId,
                offset: itemsPerPage * currentPage.current + sentMessagesLength,
                limit: itemsPerPage,
                order_by: 'DESC',
                sort_by: 'request_date',
            }
            const userMessagesHistory: {
                items: HistoryMessage[]
                count: number
            } = await axios
                .get(`${BASE_URL}history?${serializeObjectToQueryParams(params)}`, getRequestHeader(userAccessToken))
                .then((response) => response.data)

            const parsedMessageList = parseHistoryMessages(userMessagesHistory.items.sort((a, b) => a.id - b.id))

            const finalList = [...parsedMessageList, ...list]

            setList(finalList)

            if (finalList.length / 2 >= userMessagesHistory.count) {
                setHasMoreItemToFetch(false)
            }
        } catch (e) {
            console.error(e)
        }

        setLoadingData(false)
    }

    const sendMessage: MessageRequest = async (requestMessage: MessageParams) => {
        const newId = parseInt(list[list.length - 1].id, 10) + 1
        const newMessage: ParsedMessage = {
            id: `${newId}-request`,
            content: requestMessage.message,
            contentType: ContentType.Text,
            messageType: MessageType.Request,
            datetime: new Date().getTime(),
        }

        const loadingMessage: OtherMessage = {
            id: `${newId}-loading`,
            messageType: MessageType.Loading,
            datetime: new Date().getTime(),
        }

        const newList: (ParsedMessage | OtherMessage)[] = [
            ...JSON.parse(JSON.stringify(list)),
            newMessage,
            loadingMessage,
        ]

        setList(newList)

        try {
            const requestParams: FetchCompletionBody = {
                prompt: requestMessage.message,
                hint: requestMessage.selectedHint ? requestMessage.selectedHint.id : null,
                entrypoint: 4, // id piattaforma
                type:
                    (requestMessage.message.toLocaleLowerCase().indexOf('genera') !== -1 &&
                        requestMessage.message.toLocaleLowerCase().indexOf('immagine') !== -1) ||
                    (requestMessage.message.toLocaleLowerCase().indexOf('genera') !== -1 &&
                        requestMessage.message.toLocaleLowerCase().indexOf('nft') !== -1)
                        ? 'image'
                        : 'text',
                parameters: requestMessage.selectedParams.map((selectedParam) => {
                    return { idParam: selectedParam.idParam, idOption: selectedParam.idValue }
                }),
                context: requestMessage.context || null,
            }
            const newResponseObject = await performCompletion(requestParams, userAccessToken)

            if (newResponseObject) {
                setList(replaceLoadingItem(newList, parseResponseMessage(newResponseObject, newId)))
                setSentMessagesLength(sentMessagesLength + 1)
            }
        } catch (error) {
            const errorMessage: OtherMessage = {
                id: `${newId}-error`,
                messageType: MessageType.Error,
                datetime: new Date().getTime(),
            }

            setList(replaceLoadingItem(newList, errorMessage))
            console.error(error)
        }
    }

    const fetchNewPage: NextPageRequest = async () => {
        currentPage.current = currentPage.current + 1
        await fetchList()
    }

    return [list, loadingData, sentMessagesLength, sendMessage, fetchNewPage]
}

const replaceLoadingItem = (list: (ParsedMessage | OtherMessage)[], newMessage: ParsedMessage | OtherMessage) => {
    const copiedList = JSON.parse(JSON.stringify(list))

    copiedList.splice(list.length - 1, 1, newMessage)

    return copiedList
}

export interface SectionListGroup {
    title: string
    data: (ParsedMessage | OtherMessage)[]
}

export const groupListByDate = (list: (ParsedMessage | OtherMessage)[]) => {
    const groupedMessages: SectionListGroup[] = []
    const localeString = 'it'

    for (let i = 0; i < list.length; i++) {
        const foundIndex = groupedMessages.findIndex(
            (message) => message.title === new Date(list[i].datetime).toLocaleDateString(localeString)
        )

        if (foundIndex !== -1) {
            groupedMessages[foundIndex].data.push(list[i])
        } else {
            groupedMessages.push({
                data: [list[i]],
                title: new Date(list[i].datetime).toLocaleDateString(localeString),
            })
        }
    }

    return groupedMessages
}

/**
 * Mantiene lo stato precendente del valore passato
 * @param value any
 */
export const usePrevious = (value: any): any => {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}
