import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'
import GradientButton from '../../components/GradientButton/GradientButton'
import { performPaymentIntent } from '../../resources/api-constants'
import { liveStripeKey, testStripeKey } from '../../resources/other-constants'
import { useAppSelector } from '../../store/reducers/store'
import { Plan } from '../../types/data'
import CircularLoader from '../InfiniteLoader/CircularLoader'
import './PlanModal.sass'

const clientStripeKey = process.env.NODE_ENV === 'development' ? testStripeKey : liveStripeKey

const stripePromise = loadStripe(clientStripeKey)

interface PaymentFormProps {
    amount: number
    onPaymentSuccess: () => void
}

const PaymentForm: React.FC<PaymentFormProps> = (props) => {
    const stripe = useStripe()
    const elements = useElements()
    const [loading, setLoading] = useState<boolean>(false)

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }
        setLoading(true)

        const result = await stripe.confirmPayment({
            elements,
            redirect: 'if_required',
            confirmParams: {
                return_url: '',
            },
        })

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.error(result.error.message)
        } else {
            props.onPaymentSuccess()
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
        setLoading(false)
    }

    return (
        <>
            <div className="payment-element-wrapper">
                <PaymentElement />
            </div>

            <GradientButton disabled={loading} onClick={() => void handleSubmit()}>
                Paga {props.amount / 100}€
            </GradientButton>
        </>
    )
}

interface Props {
    selectedPlan: Plan
    onSuccess: () => void
}

export const PaymentPage: React.FC<Props> = (props) => {
    const [clientSecret, setClientSecret] = useState<string>('')
    const [loading, setLoading] = useState(false)

    const user = useAppSelector((store) => store.user)

    useEffect(() => {
        void perform()
    }, [])

    const perform = async () => {
        setLoading(true)
        const paymentResponse = await performPaymentIntent(
            {
                amount: props.selectedPlan.amount,
            },
            user.accessToken ?? ''
        )

        setClientSecret(paymentResponse.client_secret)
        setLoading(false)
    }

    if (!clientSecret) {
        if (loading) {
            return (
                <div className="circular-loader-screen">
                    <CircularLoader />
                </div>
            )
        }
        return null
    }

    const options = {
        // passing the client secret obtained from the server
        clientSecret,
    }

    return (
        <Elements stripe={stripePromise} options={options}>
            <PaymentForm amount={props.selectedPlan.amount} onPaymentSuccess={() => props.onSuccess()} />
        </Elements>
    )
}

export default PaymentPage
