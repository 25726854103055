import { Topic } from '@22hbg/peperoni-ai-sdk'
import React from 'react'
import { truncateText } from '../../utility/functions'
import GradientBox from '../GradientBox/GradientBox'
import './TopicCard.sass'

const gradientColors = ['#FF7F00', '#FF1D00', '#F500FF', '#6BFF00', '#00BAFF']

interface TopicCardProps {
    topic: Topic
    index: number
    onClick: () => void
}

const TopicCard: React.FC<TopicCardProps> = (props) => {
    return (
        <div className="topic-card" onClick={() => props.onClick()}>
            <GradientBox colors={[gradientColors[props.index % gradientColors.length], '#316EDE']} />
            <div className="topic-card__text-container">
                <span className="topic-card__text-container__title">{props.topic.title}</span>
                <span className="topic-card__text-container__description">
                    {truncateText(props.topic.description || '', 60)}
                </span>
            </div>
        </div>
    )
}

export default TopicCard
