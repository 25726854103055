import React, { useState } from 'react'
import GradientButton from '../../components/GradientButton/GradientButton'
import { useAppSelector } from '../../store/reducers/store'
import { Plan } from '../../types/data'
import './PlanModal.sass'

interface Props {
    onNextPage: (selectedPlan: Plan) => void
}

export const PlanPage: React.FC<Props> = (props) => {
    const data = useAppSelector((data) => data.data)
    const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null)

    const formatPlanPrice = (price: number) => {
        if (!price || price < 0) return '0€'

        return `${price / 100} €`
    }

    return (
        <>
            <div className="available-plans-list">
                {data.plans.map((plan) => (
                    <div
                        className={`plan-card ${plan.id === selectedPlan?.id ? 'selected-plan-card' : ''}`}
                        key={plan.sku}
                        onClick={() => setSelectedPlan(plan)}
                    >
                        <div className="plan-card__left-section">
                            <span className="plan-title">{plan.title} Pack</span>
                            <span className="plan-token-balance">{plan.tokens} coin</span>
                        </div>
                        <div>
                            <span className="plan-price">{formatPlanPrice(plan.amount)}</span>
                        </div>
                    </div>
                ))}
            </div>
            <p className="available-plans-subtitle">* Ogni richiesta utilizza almeno 1 coin.</p>
            <GradientButton
                disabled={selectedPlan?.id === -1}
                onClick={() => {
                    if (selectedPlan !== null) {
                        props.onNextPage(selectedPlan)
                    }
                }}
            >
                Continua
            </GradientButton>
            <a
                style={{ fontWeight: 600, marginTop: 16, fontSize: 14 }}
                className="sidebar-external-link"
                href="https://peperoni.ai/"
                target="_blank"
                rel="noreferrer"
            >
                Scopri altri prodotti sullo store di Peperoni
            </a>
        </>
    )
}

export default PlanPage
