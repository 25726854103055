import React from 'react'
import './GradientBox.sass'

interface GradientBoxProps {
    colors: string[]
}

const GradientBox: React.FC<GradientBoxProps> = (props) => {
    return (
        <div
            className="gradient-box"
            style={{
                background: 'rgb(2,0,36)',
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                background: `linear-gradient(160deg, ${props.colors[0]} 0%, ${props.colors[1]} 35%, ${props.colors[1]} 68%, ${props.colors[1]} 100%)`,
            }}
        />
    )
}

export default GradientBox
