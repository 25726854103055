import React, { useEffect, useMemo, useState } from 'react'
import SectionTitle from '../components/SectionTitle/SectionTitle'
import { logoutUser } from '../store/actions/user'
import { useAppDispatch, useAppSelector } from '../store/reducers/store'
import pepWhiteLogo from '../resources/assets/images/peperoni_white_logo.png'
import TextInput, { TextType } from '../components/TextInput/TextInput'
import GradientButton from '../components/GradientButton/GradientButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTES } from '../resources/routes-constants'
import '../styles/Pages/ProfilePage.sass'
import { passwordRegEx } from '../resources/regex-constants'
import AlertModal from '../customComponents/AlertModal/AlertModal'
import { patchUserData } from '../store/actions/thunk_actions'
import {
    ChangePasswordParameters,
    deleteChatHistory,
    performUserDeletionRequest,
    updateLoggedUserPassword,
} from '../resources/api-constants'
import pkg from '../../package.json'
import { privacyPolicyURL } from './auth/SignInPage'

const logoutMessage = 'Il tuo profilo verrà disconnesso da questo dispositivo.'
const clearHistoryMessage = 'Il tuo storico messaggi verrà svuotato.'
const requestAccountDeletionMessage = 'Verrà inoltrata una richiesta di eliminazione relativa al tuo profilo.'

const ProfilePage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [username, setUsername] = useState('')
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [userDataError, setUserDataError] = useState('')
    const [deleteAccountMessage, setDeleteAccountMessage] = useState('')
    const [updatePasswordIsLoading, setUpdatePasswordIsLoading] = useState(false)
    const [updateUserDataIsLoading, setUpdateUserDataIsLoading] = useState(false)
    const [modalData, setModalData] = useState<{ callback: () => void; message: string } | null>(null)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const userDataIsCorrect = useMemo(() => {
        return [firstName, lastName, username].findIndex((elem) => elem === '') === -1
    }, [firstName, lastName, username])

    const changePasswordDataIsCorrect = useMemo(() => {
        return (
            [oldPassword, newPassword].findIndex((elem) => elem === '') === -1 &&
            oldPassword !== newPassword &&
            passwordRegEx.test(newPassword)
        )
    }, [newPassword, oldPassword, passwordRegEx])

    useEffect(() => {
        const userInfo = user.loggedUserInfo?.user_info
        if (!userInfo) return
        setFirstName(userInfo.first_name)
        setLastName(userInfo.last_name)
        setUsername(userInfo.username)
    }, [user.loggedUserInfo])

    const updateUserData = async () => {
        setUpdateUserDataIsLoading(true)
        try {
            await dispatch(
                patchUserData({
                    authToken: user.accessToken || '',
                    params: { firstName, lastName, username },
                })
            )
        } catch (error) {
            console.error(error)
            setUserDataError('Errore: ricontrolla i valori inseriti e riprova.')
        }
        setUpdateUserDataIsLoading(false)
    }

    const updateUserPassword = async () => {
        setUpdatePasswordIsLoading(true)
        try {
            const changePassParams: ChangePasswordParameters = {
                new_password: newPassword,
                new_password_confirm: newPassword,
            }
            await updateLoggedUserPassword(user.accessToken || '', changePassParams)
            setOldPassword('')
            setNewPassword('')
        } catch (error) {
            console.error(error)
            setPasswordError('Errore: reinserisci le password e riporova.')
        }
        setUpdatePasswordIsLoading(false)
    }

    const saveActionForModalConfirm = (callback: () => void, message: string) => {
        setModalData({
            message,
            callback,
        })
    }

    return (
        <>
            {modalData !== null && (
                <AlertModal
                    onClose={() => setModalData(null)}
                    onConfirm={modalData.callback}
                    message={modalData.message}
                />
            )}
            <div className="profile-page-main">
                <div className="profile-page-main__inner">
                    <div className="profile-page-main__inner__header">
                        <div className="elements-row">
                            <div className="main-logo-container">
                                <img src={pepWhiteLogo} alt="PeperoniAI" />
                            </div>
                            <Link className="profile-internal-link-container" to={ROUTES.HOMEPAGE_ROUTE}>
                                <div className="profile-internal-link-container__link">
                                    <FontAwesomeIcon icon={faChevronLeft} color="#fff" />
                                    Indietro
                                </div>
                            </Link>
                        </div>
                        <SectionTitle text="Profilo" />
                    </div>
                    <div className="profile-input-section">
                        <div className="profile-input-container">
                            <span>Nome:</span>
                            <TextInput
                                textType={TextType.Text}
                                placeholder="Nome"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                        <div className="profile-input-container">
                            <span>Cognome:</span>
                            <TextInput
                                textType={TextType.Text}
                                placeholder="Cognome"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                        <div className="profile-input-container">
                            <span>Username:</span>
                            <TextInput
                                textType={TextType.Text}
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        {userDataError && (
                            <div style={{ marginBottom: 16 }} className="error-container">
                                {userDataError}
                            </div>
                        )}
                        <GradientButton
                            disabled={!userDataIsCorrect || updateUserDataIsLoading}
                            onClick={() => void updateUserData()}
                        >
                            Modifica
                        </GradientButton>
                    </div>
                    <div className="profile-input-section">
                        <div className="profile-input-container">
                            <span>Password attuale:</span>
                            <TextInput
                                textType={TextType.Password}
                                placeholder="Password attuale"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                            />
                        </div>
                        <div className="profile-input-container">
                            <span>Nuova password:</span>
                            <TextInput
                                textType={TextType.Password}
                                placeholder="Nuova password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </div>
                        <p className="password-suggestion-message">
                            La nuova password deve essere lunga almeno 8 caratteri e al massimo 40 e deve contenere
                            almeno una lettera maiuscola, una minuscola, un numero e un simbolo (.!@#$%^&*_-+=|?).
                        </p>
                        {passwordError && (
                            <div style={{ marginBottom: 16 }} className="error-container">
                                {passwordError}
                            </div>
                        )}
                        <GradientButton
                            disabled={!changePasswordDataIsCorrect || updatePasswordIsLoading}
                            onClick={() => void updateUserPassword()}
                        >
                            Cambia password
                        </GradientButton>
                    </div>
                    <div className="profile-input-section dangerous-links-section">
                        <div
                            className="dangerous-action-link"
                            onClick={() => {
                                saveActionForModalConfirm(() => {
                                    dispatch(logoutUser())
                                    setModalData(null)
                                    navigate(ROUTES.LOGIN_ROUTE)
                                }, logoutMessage)
                            }}
                        >
                            Disconnetti
                        </div>
                        <div
                            className="dangerous-action-link"
                            onClick={() => {
                                saveActionForModalConfirm(async () => {
                                    await deleteChatHistory(user.accessToken || '')
                                    setModalData(null)
                                }, clearHistoryMessage)
                            }}
                        >
                            Cancella cronologia conversazioni
                        </div>
                        {deleteAccountMessage && <div className="success-container">{deleteAccountMessage}</div>}
                        <div
                            className="dangerous-action-link"
                            onClick={() => {
                                saveActionForModalConfirm(async () => {
                                    await performUserDeletionRequest(
                                        user.accessToken || '',
                                        user.loggedUserInfo?.sso_uuid || ''
                                    )
                                    setDeleteAccountMessage(
                                        'Ti abbiamo inviato una mail per completare la procedura di eliminazione account. Se non la trovi, controlla la posta indesiderata.'
                                    )
                                    setModalData(null)
                                }, requestAccountDeletionMessage)
                            }}
                        >
                            Richiedi eliminazione account
                        </div>
                    </div>
                    <a
                        className="profile-internal-link-container"
                        href={privacyPolicyURL}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div className="profile-internal-link-container__link">Privacy Policy</div>
                    </a>
                    <span className="password-suggestion-message basic-text-paragraph">PeperoniAI v{pkg.version}</span>
                </div>
            </div>
        </>
    )
}

export default ProfilePage
