import axios from 'axios'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import GradientButton from '../../components/GradientButton/GradientButton'
import SectionTitle from '../../components/SectionTitle/SectionTitle'
import TextInput, { TextType } from '../../components/TextInput/TextInput'
import { ROUTES } from '../../resources/routes-constants'
import { fetchData } from '../../store/actions/thunk_actions'
import { setAccessToken } from '../../store/actions/user'
import { useAppDispatch } from '../../store/reducers/store'
import { SSOUserInfo } from '../../types/data'
import './../../styles/Pages/auth_pages.sass'
import pepWhiteLogo from '../../resources/assets/images/peperoni_white_logo.png'

export const SSO_PROD_URL = 'https://api.peperoni.ai/22sso-api/'
export const BASE_URL = 'https://solutions.api-services.cloud/peperoni-ai/'

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState('')

    const dispatch = useAppDispatch()

    const login = async () => {
        try {
            setIsLoading(true)
            setError('')
            const ssoLoggedUserData: SSOUserInfo = await axios
                .post(`${SSO_PROD_URL}auth/login`, { email, password })
                .then((response) => response.data)

            await dispatch(setAccessToken(ssoLoggedUserData.auth_token))
            await dispatch(fetchData())
        } catch (error) {
            console.error(error)
            setError('Errore: ricontrolla le credenziali inserite e riprova.')
        }
        setIsLoading(false)
    }

    return (
        <div className="auth-page-main">
            <div className="auth-page-main__inner">
                <div className="auth-page-main__inner__header">
                    <div className="main-logo-container">
                        <img src={pepWhiteLogo} alt="PeperoniAI" />
                    </div>
                    <SectionTitle text="Accedi" />
                </div>
                <div className="auth-input-container">
                    <TextInput
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        textType={TextType.Email}
                        placeholder="Email"
                    />
                    <TextInput
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        textType={TextType.Password}
                        placeholder="Password"
                    />
                </div>
                {error && <div className="error-container">{error}</div>}
                <GradientButton disabled={isLoading || !password || !email} onClick={() => void login()}>
                    {isLoading ? 'Accesso in corso...' : 'Accedi'}
                </GradientButton>
                <div className="auth-internal-link-container">
                    <Link className="auth-internal-link" to={ROUTES.RESET_PASSWORD_ROUTE}>
                        Password dimenticata
                    </Link>
                    <Link className="auth-internal-link" to={ROUTES.SIGNIN_ROUTE}>
                        Crea un account
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default LoginPage
