import React, { useMemo, useState } from 'react'
import SectionTitle from '../../components/SectionTitle/SectionTitle'
import { Plan } from '../../types/data'
import CircularLoader from '../InfiniteLoader/CircularLoader'
import PaymentPage from './PaymentPage'
import './PlanModal.sass'
import PlanPage from './PlanPage'

interface PlanModalProps {
    onClose: () => void
    onSuccess: () => void
}

enum PlanModalPage {
    Plans,
    Payment,
}

const PlanModal: React.FC<PlanModalProps> = (props) => {
    const [selectedPage, setSelectedPage] = useState<PlanModalPage>(PlanModalPage.Plans)
    const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null)

    const currentPage = useMemo(() => {
        if (selectedPage === PlanModalPage.Plans) {
            return (
                <PlanPage
                    onNextPage={(plan) => {
                        setSelectedPlan(plan)
                        setSelectedPage(PlanModalPage.Payment)
                    }}
                />
            )
        }

        if (selectedPage === PlanModalPage.Payment && selectedPlan !== null) {
            return <PaymentPage selectedPlan={selectedPlan} onSuccess={() => props.onSuccess()} />
        }

        return (
            <div className="circular-loader-screen">
                <CircularLoader />
            </div>
        )
    }, [selectedPage])

    return (
        <div className="modal">
            <div className="dark-cover" onClick={() => props.onClose()} />
            <div className="modal-panel">
                <SectionTitle text="Acquista" />
                {currentPage}
            </div>
        </div>
    )
}

export default PlanModal
