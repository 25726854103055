import React, { ReactNode } from 'react'
import './Tooltip.sass'

interface TooltipProps {
    text: string
    children: ReactNode
    position: 'top' | 'bottom' | 'left' | 'right'
}

const Tooltip: React.FC<TooltipProps> = (props) => {
    return (
        <div className="tooltip-wrapper">
            <span className={`tooltip tooltip-${props.position}`}>{props.text}</span>
            {props.children}
        </div>
    )
}

export default Tooltip
