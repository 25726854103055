import React, { useMemo } from 'react'
import './CoinsBalance.sass'
import pepCoin from '../../resources/assets/images/pep_coin.png'
import { useAppSelector } from '../../store/reducers/store'

interface Props {
    onClick: () => void
}

const CoinsBalance: React.FC<Props> = (props) => {
    const user = useAppSelector((data) => data.user)

    const getUserTokenBalance = useMemo(() => {
        return user.loggedUserInfo?.token_balance || 0
    }, [user.loggedUserInfo])

    return (
        <div className="coins-balance" onClick={() => props.onClick()}>
            <div className="coins-image-container">
                <img src={pepCoin} alt="Coins" />
            </div>
            <label className="coins-balance-value">{getUserTokenBalance}</label>
        </div>
    )
}

export default CoinsBalance
