import moment from 'moment'

/**
 * Returns a string truncated on a specified index
 * @param string the string that will get truncated
 * @param length the index at which the string gets truncated
 * @returns truncated string
 */
export const truncateText = (string: string, length: number) => {
    if (!string) return string
    return string.length > length ? `${string.substring(0, length - 1)}...` : string
}

export const getRequestHeader = (token: string, type?: string) => {
    return { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': type || 'application/json' } }
}

export const serializeObjectToQueryParams = (object: any) => {
    const str: string[] = []

    // eslint-disable-next-line no-restricted-syntax
    for (const p in object) {
        // eslint-disable-next-line no-prototype-builtins
        if (object.hasOwnProperty(p)) {
            str.push(`${encodeURIComponent(p)}=${encodeURIComponent(object[p])}`)
        }
    }

    return str.join('&')
}

const fulldays = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato']
const months = [
    'gennaio',
    'febbraio',
    'marzo',
    'aprile',
    'maggio',
    'giugno',
    'luglio',
    'agosto',
    'settembre',
    'ottobre',
    'novembre',
    'dicembre',
]

export const formatDateToFriendlyString = (someDateTimeStamp: string): string => {
    const dt = moment(someDateTimeStamp, 'DD/M/yyyy')
    const date = dt.get('date')
    const month = months[dt.get('month')]
    const diffDays = new Date().getDate() - date
    const diffMonths = new Date().getMonth() - dt.get('month')
    const diffYears = new Date().getFullYear() - dt.get('year')

    if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
        return 'Oggi'
    }
    if (diffYears === 0 && diffDays === 1) {
        return 'Ieri'
    }
    if (diffYears === 0 && diffDays < -1 && diffDays > -7) {
        return fulldays[dt.get('day')]
    }
    if (diffYears >= 1) {
        return `${date} ${month}, ${dt.get('year')}`
    }

    return `${date} ${month}`
}
