import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import LoginPage from './pages/auth/LoginPage'
import ResetPasswordPage from './pages/auth/ResetPasswordPage'
import SingInPage from './pages/auth/SignInPage'
import HomePage from './pages/HomePage'
import NotFoundPage from './pages/NotFoundPage'
import ProfilePage from './pages/ProfilePage'
import { ROUTES } from './resources/routes-constants'
import { fetchData } from './store/actions/thunk_actions'
import { useAppDispatch, useAppSelector } from './store/reducers/store'
import './styles/main.sass'
import { usePrevious } from './utility/hooks'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const RootComponent: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (user.accessToken !== null) {
            void dispatch(fetchData())
        }
    }, [])

    const previousUserToken: string | undefined = usePrevious(user.accessToken)

    useEffect(() => {
        if (previousUserToken !== undefined && user.accessToken !== null && previousUserToken !== user.accessToken) {
            void dispatch(fetchData())
        }
    }, [user.accessToken])

    return (
        <Router>
            <ToastContainer />
            {user.loggedUserInfo !== null ? (
                <Routes>
                    <Route path="*" element={<NotFoundPage />} />
                    <Route path={ROUTES.HOMEPAGE_ROUTE} element={<HomePage />} />
                    <Route path={ROUTES.PROFILE_ROUTE} element={<ProfilePage />} />
                </Routes>
            ) : (
                <Routes>
                    <Route path="*" element={<NotFoundPage />} />
                    <Route path={ROUTES.LOGIN_ROUTE} element={<LoginPage />} />
                    <Route path={ROUTES.SIGNIN_ROUTE} element={<SingInPage />} />
                    <Route path={ROUTES.RESET_PASSWORD_ROUTE} element={<ResetPasswordPage />} />
                </Routes>
            )}
        </Router>
    )
}

export default RootComponent
