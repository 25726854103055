import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PlanModal from '../../customComponents/PlanModal/PlanModal'
import { ROUTES } from '../../resources/routes-constants'
import { fetchUserInfo } from '../../store/actions/thunk_actions'
import { setUserPreferredFontSize } from '../../store/actions/user'
import { useAppDispatch, useAppSelector } from '../../store/reducers/store'
import { ToastSuccess } from '../../utility/toast'
import CoinsBalance from '../CoinsBalance/CoinsBalance'
import Tooltip from '../Tooltip/Tooltip'
import './Header.sass'

interface HeaderProps {
    title: string
    hideUserInfo?: boolean
}

const Header: React.FC<HeaderProps> = (props) => {
    const user = useAppSelector((data) => data.user)
    const [modalIsVisible, setModalIsVisible] = useState(false)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const fetchUserBalance = async () => {
        try {
            await dispatch(fetchUserInfo())
        } catch (error) {
            console.error(error)
        }
    }

    const changeFontSize = (operator: '-' | '+') => {
        const starter = user.userPreferredFontSize || 14
        const calc = operator === '+' ? starter + 2 : starter - 2
        document.documentElement.style.setProperty('--font-size', `${calc.toString()}px`)
        dispatch(setUserPreferredFontSize(calc))
    }

    return (
        <>
            {modalIsVisible && (
                <PlanModal
                    onClose={() => setModalIsVisible(false)}
                    onSuccess={() => {
                        setModalIsVisible(false)
                        setTimeout(() => {
                            void fetchUserBalance()
                        }, 5000)
                        ToastSuccess('Pagamento effettuato con successo. A breve riceverai i coin acquistati.')
                    }}
                />
            )}
            <div className="main-header">
                <div className="main-header__left-section">
                    <span className="main-header__left-section__title">{props.title}</span>
                    <div className="main-header__left-section__font-size">
                        <div
                            className={`font-change-button ${
                                user.userPreferredFontSize === 10 ? 'disabled-font-size-button' : ''
                            }`}
                            onClick={() => {
                                if (user.userPreferredFontSize === 10) return
                                changeFontSize('-')
                            }}
                        >
                            <span className="decrease">A</span>
                        </div>
                        <div
                            className={`font-change-button ${
                                user.userPreferredFontSize === 20 ? 'disabled-font-size-button' : ''
                            }`}
                            onClick={() => {
                                if (user.userPreferredFontSize === 20) return
                                changeFontSize('+')
                            }}
                        >
                            <span className="increase">A</span>
                        </div>
                    </div>
                </div>
                {!props.hideUserInfo ? (
                    <div className="main-header__right-section">
                        <CoinsBalance onClick={() => setModalIsVisible(true)} />
                        <Tooltip position="bottom" text="Profilo">
                            <div className="user-avatar-container" onClick={() => navigate(ROUTES.PROFILE_ROUTE)}>
                                <FontAwesomeIcon className="user-avatar-icon" icon={faUser} />
                            </div>
                        </Tooltip>
                    </div>
                ) : null}
            </div>
        </>
    )
}

export default Header
