import React from 'react'
import Lottie from 'lottie-react'
import errorAnimation from '../../resources/animations/error-message.json'
import './ChatMessage.sass'

interface Props {
    onClick: () => void
}

const ErrorMessage: React.FC<Props> = (props) => {
    return (
        <div className="error-message" onClick={() => props.onClick()}>
            <div className="error-message__lottie-container">
                <Lottie className="lottie" animationData={errorAnimation} autoPlay loop={false} />
            </div>
            <p className="error-message-text">Errore: Premi per riprovare.</p>
        </div>
    )
}

export default ErrorMessage
