import axios from 'axios'
import React, { useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import GradientButton from '../../components/GradientButton/GradientButton'
import SectionTitle from '../../components/SectionTitle/SectionTitle'
import TextInput, { TextType } from '../../components/TextInput/TextInput'
import { ROUTES } from '../../resources/routes-constants'
import { fetchData } from '../../store/actions/thunk_actions'
import { setAccessToken } from '../../store/actions/user'
import { useAppDispatch } from '../../store/reducers/store'
import pepWhiteLogo from '../../resources/assets/images/peperoni_white_logo.png'
import { passwordRegEx } from '../../resources/regex-constants'

const SSO_PROD_URL = 'https://api.peperoni.ai/22sso-api/'
export const privacyPolicyURL = 'https://22hbg.com/peperoni_ai_privacy_policy/'

const SignInPage: React.FC = () => {
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [username, setUsername] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [privacyFlag, setPrivacyFlag] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const correctData = useMemo(() => {
        return (
            [firstName, lastName, username, email, password, confirmPassword].findIndex((elem) => elem === '') === -1 &&
            password === confirmPassword &&
            privacyFlag &&
            passwordRegEx.test(password)
        )
    }, [firstName, lastName, username, email, password, confirmPassword, privacyFlag])

    const signin = async () => {
        try {
            setIsLoading(true)
            setError('')
            const registrationCredentials = {
                first_name: firstName,
                last_name: lastName,
                email,
                username,
                password,
                password_confirm: confirmPassword,
                target_platform: 'PEPERONI',
                language: 'IT',
            }

            const registeredUser = await axios
                .post(`${SSO_PROD_URL}auth/signin`, registrationCredentials)
                .then((response) => response.data)

            if (registeredUser.uuid) {
                await dispatch(setAccessToken(registeredUser.auth_token))
                await dispatch(fetchData())
            }
            navigate(ROUTES.LOGIN_ROUTE)
        } catch (error) {
            console.error(error)
            setError('Errore: ricontrolla le credenziali inserite e riprova.')
        }
        setIsLoading(false)
    }

    return (
        <div className="auth-page-main">
            <div className="auth-page-main__inner">
                <div className="auth-page-main__inner__header">
                    <div className="main-logo-container">
                        <img src={pepWhiteLogo} alt="PeperoniAI" />
                    </div>
                    <SectionTitle text="Crea account" />
                </div>
                <div className="auth-input-container">
                    <TextInput
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        textType={TextType.Text}
                        placeholder="Nome"
                        autoCapitalize
                    />
                    <TextInput
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        textType={TextType.Text}
                        placeholder="Cognome"
                        autoCapitalize
                    />
                    <TextInput
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        textType={TextType.Text}
                        placeholder="Username"
                    />
                    <TextInput
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        textType={TextType.Email}
                        placeholder="Email"
                        autoComplete={false}
                    />
                    <TextInput
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        textType={TextType.Password}
                        placeholder="Password"
                        autoComplete={false}
                    />
                    <TextInput
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        textType={TextType.Password}
                        placeholder="Conferma password"
                    />
                    <div>
                        <input
                            type="checkbox"
                            checked={privacyFlag}
                            onChange={(e) => setPrivacyFlag(e.target.checked)}
                        />
                        <span>
                            Confermo di aver letto e di accettare la{' '}
                            <a className="auth-internal-link" href={privacyPolicyURL} target="_blank" rel="noreferrer">
                                Privacy Policy
                            </a>
                        </span>
                    </div>
                    <p className="password-suggestion-message">
                        La nuova password deve essere lunga almeno 8 caratteri e al massimo 40 e deve contenere almeno
                        una lettera maiuscola, una minuscola, un numero e un simbolo (.!@#$%^&*_-+=|?).
                    </p>
                </div>
                {error && <div className="error-container">{error}</div>}
                <GradientButton disabled={!correctData} onClick={() => void signin()}>
                    {isLoading ? 'Registrazione in corso...' : 'Registrati'}
                </GradientButton>
                <div className="auth-internal-link-container">
                    <Link className="auth-internal-link" to={ROUTES.LOGIN_ROUTE}>
                        Accedi
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default SignInPage
