import axios from 'axios'
import { BASE_URL, SSO_PROD_URL } from '../pages/auth/LoginPage'
import { getRequestHeader } from '../utility/functions'

export interface PaymentIntentRequestBody {
    amount: number
}

export interface ChangePasswordParameters {
    new_password: string
    new_password_confirm: string
}

export const performPaymentIntent = async (body: PaymentIntentRequestBody, authToken: string): Promise<any> => {
    try {
        const response = await axios
            .post(`${BASE_URL}payment-intent`, body, getRequestHeader(authToken))
            .then((response) => response.data)

        return response
    } catch (e) {
        console.error(e)
    }
}

export const updateLoggedUserPassword = async (authToken: string, body: ChangePasswordParameters): Promise<void> => {
    try {
        await axios.post(`${SSO_PROD_URL}/auth/set-new-password`, body, getRequestHeader(authToken))
    } catch (e) {
        console.error(e)
    }
}

export const performUserDeletionRequest = async (authToken: string, userId: string): Promise<any> => {
    try {
        const res = await axios
            .post(`${SSO_PROD_URL}users/${userId}/request-deletion`, {}, getRequestHeader(authToken))
            .then((response) => response.data)
        return res
    } catch (e) {
        console.error(e)
        return
    }
}

export const deleteChatHistory = async (authToken: string): Promise<any> => {
    try {
        await axios.delete(`${BASE_URL}history`, getRequestHeader(authToken)).then((response) => response.data)
    } catch (e) {
        console.error(e)
    }
}
