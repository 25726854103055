import { createReducer } from '@reduxjs/toolkit'
import { Hint, Topic } from '@22hbg/peperoni-ai-sdk'
import { setPlans, setSelectedHint, setTopics } from '../actions/data'
import { Plan } from '../../types/data'

interface DataReducer {
    topics: Topic[]
    selectedHint: Hint | undefined
    plans: Plan[]
}

const initialState: DataReducer = {
    topics: [],
    selectedHint: undefined,
    plans: [],
}

const dataReducer = createReducer<DataReducer>(initialState, (builder) => {
    builder.addCase(setTopics, (state, action) => {
        state.topics = action.payload
    })
    builder.addCase(setSelectedHint, (state, action) => {
        state.selectedHint = action.payload
    })
    builder.addCase(setPlans, (state, action) => {
        state.plans = action.payload
    })
})

export default dataReducer
