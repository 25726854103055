import React, { useEffect, useId, useRef } from 'react'
import ContentEditable from 'react-contenteditable'
import './ChatUserInput.sass'

interface ChatUserInputProps {
    value: string
    onChange: (newVal: string) => void
}

const ChatUserInput: React.FC<ChatUserInputProps> = (props) => {
    const textInputRef = useRef<HTMLInputElement | null>(null)
    const inputId = useId()

    useEffect(() => {
        const customInput = document.getElementById(inputId)
        if (!props.value || !customInput) return
        customInput.innerHTML = props.value
    }, [props.value])

    return (
        <div className="chat-user-input-wrapper">
            <div className="chat-user-input">
                <ContentEditable
                    placeholder="Dimmi come posso aiutarti..."
                    className="chat-user-input-editable"
                    innerRef={textInputRef}
                    html={props.value} // innerHTML of the editable div
                    disabled={false} // use true to disable editing
                    onChange={(e) => props.onChange(e.currentTarget.textContent)}
                />
            </div>
        </div>
    )
}

export default ChatUserInput
