import React from 'react'
import GradientButton from '../../components/GradientButton/GradientButton'
import SectionTitle from '../../components/SectionTitle/SectionTitle'
import './AlertModal.sass'

interface AlertModalProps {
    message: string
    onConfirm: () => void
    onClose: () => void
}

const AlertModal: React.FC<AlertModalProps> = (props) => {
    return (
        <div className="modal">
            <div className="dark-cover" onClick={() => props.onClose()} />
            <div className="modal-panel">
                <SectionTitle text="Attenzione" />
                <div className="alert-message">{props.message}</div>
                <div className="alert-modal-footer">
                    <GradientButton type="grayscale" onClick={() => props.onClose()}>
                        Annulla
                    </GradientButton>
                    <GradientButton onClick={() => props.onConfirm()}>Continua</GradientButton>
                </div>
            </div>
        </div>
    )
}

export default AlertModal
