import React, { useCallback, useEffect, useId, useMemo, useState } from 'react'
import { ContentType, timestampToString, ParsedMessage, MessageType } from '@22hbg/peperoni-ai-sdk'
import './ChatMessage.sass'
import pepWhiteLogo from '../../resources/assets/images/peperoni_white_logo.png'

interface Props {
    message: ParsedMessage
}

const ChatMessage: React.FC<Props> = (props) => {
    const [isImageInvalid, setIsImageInvalid] = useState<boolean>(false)
    const itemId = useId()

    const handleSelectionCopy = (e: any) => {
        const selection = document.getSelection()
        if (!selection) return
        e.clipboardData.setData('text/plain', selection.toString())
        e.preventDefault()
    }

    useEffect(() => {
        const messageBox = document.getElementById(itemId)
        messageBox?.addEventListener('copy', handleSelectionCopy)
        return () => {
            messageBox?.removeEventListener('copy', handleSelectionCopy)
        }
    }, [])

    const messageComponent = useMemo(() => {
        if (props.message.contentType === ContentType.Text) {
            return <div className="text-wrapper">{props.message.content}</div>
        }

        if (isImageInvalid) {
            return (
                <div className="message-image-container message-invalid-image">
                    <img src={pepWhiteLogo} alt="Resource no longer available" />
                    <span className="invalid-image-message">Questa immagine non è più disponibile.</span>
                </div>
            )
        }

        return (
            <div className="message-image-container">
                <img
                    src={props.message.content}
                    alt="Message"
                    onError={(e) => {
                        e.preventDefault()
                        setIsImageInvalid(true)
                    }}
                />
            </div>
        )
    }, [isImageInvalid])

    const parsedTime = useMemo(() => timestampToString(props.message.datetime), [])

    const messageClassByType = useCallback((messageType: MessageType): string => {
        switch (messageType) {
            case MessageType.Response:
                return 'response'
            case MessageType.Request:
                return 'request'
            case MessageType.Loading:
                return 'loading'
            case MessageType.Error:
            default:
                return 'error'
        }
    }, [])

    return (
        <div id={itemId} className={`message-box ${messageClassByType(props.message.messageType)}-message`}>
            <div className="message-bubble">
                <div>{messageComponent}</div>
                <div
                    className="message-footer"
                    onMouseDown={() => false}
                    onSelectCapture={() => false}
                    onSelect={() => false}
                >
                    {props.message.tokenSpent !== undefined && (
                        <div className="token-spent-wrapper">
                            <label
                                className="token-spent-wrapper__count"
                                data-unselectable={props.message.tokenSpent}
                            />
                            <div className="token-spent-wrapper__image-container">
                                <img src={pepWhiteLogo} alt="PeperoniAI" />
                            </div>
                        </div>
                    )}
                    <span className="time-indicator" data-unselectable={parsedTime} />
                </div>
            </div>
        </div>
    )
}

export default ChatMessage
