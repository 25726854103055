import React from 'react'
import Lottie from 'lottie-react'
import loadingAnimation from '../../resources/animations/infinite-scroll-loader.json'
import './ChatMessage.sass'

const LoadingMessage: React.FC = () => {
    return (
        <div className="loading-message">
            <Lottie className="lottie" animationData={loadingAnimation} autoPlay loop />
        </div>
    )
}

export default LoadingMessage
