import { Hint } from '@22hbg/peperoni-ai-sdk'
import React from 'react'
import { setSelectedHint } from '../../store/actions/data'
import { useAppDispatch } from '../../store/reducers/store'
import { truncateText } from '../../utility/functions'
import './HintCard.sass'

interface Props {
    hint: Hint
    index: number
}

const HintCard: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch()

    return (
        <div
            className={`hint-card ${props.index % 2 === 0 ? 'even-hint' : ''}`}
            onClick={() => dispatch(setSelectedHint(props.hint))}
        >
            <div className="hint-card__text-container">
                <span className="hint-card__text-container__title">{props.hint.title}</span>
                <span className="hint-card__text-container__description">
                    {truncateText(props.hint.description || '', 60)}
                </span>
            </div>
        </div>
    )
}

export default HintCard
